import logo200Image from "assets/img/logo/logo.png";
import sidebarBgImage from "assets/img/sidebar/sidebar-0.jpg";
import SourceLink from "components/SourceLink";
import React from "react";
import {
  MdPersonAdd,
  MdGroupAdd,
  MdRadioButtonChecked,
  MdKeyboardArrowDown,
  MdFormatListBulleted,
} from "react-icons/md";
import { NavLink } from "react-router-dom";
import {
  Collapse,
  Nav,
  Navbar,
  NavItem,
  NavLink as BSNavLink,
} from "reactstrap";
import bn from "utils/bemnames";

var accessList = { 6: [10], 8: [11, 12, 13], 25: [52, 53], 26: [54, 55] };

const sidebarBackground = {
  backgroundImage: `url("${sidebarBgImage}")`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
};

const navIntegra = [
  {
    to: "/integra-produk",
    id: "25",
    name: "Master Produk",
    exact: true,
    Icon: MdRadioButtonChecked,
  },
];

const navBridgingIntegra = [
  {
    to: "/integra-headerBridging",
    id: "29",
    name: "Bridging Apotek Online",
    exact: true,
    Icon: MdRadioButtonChecked,
  },
];

const navDonorApotekOnline = [
  {
    to: "/integra-registrasiOutlet",
    name: "Daftar Donor",
    exact: true,
    Icon: MdRadioButtonChecked,
  },
  {
    to: "/integra-registeredOutlet",
    name: "Sudah Donor",
    exact: true,
    Icon: MdRadioButtonChecked,
  },
];

const navIndoApotik = [
  {
    to: "/integra-indoApotik",
    id: "29",
    name: "Bridging Indo Apotek",
    exact: true,
    Icon: MdRadioButtonChecked,
  },
];

const navBridgingGreen = [
  {
    to: "/integra-bridgingGreen",
    id: "29",
    name: "Bridging Green",
    exact: true,
    Icon: MdRadioButtonChecked,
  },
];

const navBridgingRainbowPlus = [
  {
    to: "/integra-bridgingRainbowPlus",
    id: "29",
    name: "Bridging Rainbow Plus",
    exact: true,
    Icon: MdRadioButtonChecked,
  },
];

const navBridgingCenturyRainbowPlus = [
  {
    to: "/integra-bridgingCenturyRainbowPlus",
    id: "29",
    name: "Bridging Century Rainbow Plus",
    exact: true,
    Icon: MdRadioButtonChecked,
  },
];

const navBridgingPelapakinternal = [
  {
    to: "/integra-bridgingPelapakinternal",
    id: "29",
    name: "Bridging Pelapakinternal",
    exact: true,
    Icon: MdRadioButtonChecked,
  },
];

const navOrderAO = [
  {
    to: "/order-ao",
    id: "26",
    name: "Order AO",
    exact: true,
    Icon: MdRadioButtonChecked,
  },
];

const navRainbowPlus = [
  {
    to: "/sales-rainbowPlus",
    id: "26",
    name: "Sales Rainbow Plus",
    exact: true,
    Icon: MdRadioButtonChecked,
  },
];

const navProductPlafon = [
  {
    to: "/product-plafon",
    id: "26",
    name: "Product Plafon",
    exact: true,
    Icon: MdRadioButtonChecked,
  },
];

const navItems = [
  // { to: '/', id: '191', name: 'dashboard', exact: true, Icon: MdDashboard },
];

const bem = bn.create("sidebar");

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    if (window.localStorage.getItem("accessList")) {
      accessList = JSON.parse(window.localStorage.getItem("accessList"));
    } else {
    }
  }
  state = {
    isOpenIntegra: false,
    isOpenBridgingIntegra: false,
  };

  handleClick = (name) => () => {
    this.setState((prevState) => {
      const isOpen = prevState[`isOpen${name}`];

      return {
        [`isOpen${name}`]: !isOpen,
      };
    });
  };

  //added by Master I team at 11/10/2019
  refreshSamePage = (currPath, toPath) => () => {
    var temporary = "http://localhost:3000" + toPath;
    console.log(currPath + " " + temporary);
    if (currPath === temporary) {
      window.location.reload(false);
    }
  };

  allFound = (master) => {
    return master.some((menu) => Object.keys(accessList).includes(menu.id));
  };

  render() {
    return (
      <aside className={bem.b()} data-image={sidebarBgImage}>
        <div className={bem.e("background")} style={sidebarBackground} />
        <div className={bem.e("content")}>
          <Navbar>
            <SourceLink className="navbar-brand d-flex">
              <img
                src={logo200Image}
                width="40"
                height="30"
                className="pr-2"
                alt=""
              />
              <span className="text-white">Integra B2B</span>
            </SourceLink>
          </Navbar>

          <Nav vertical>
            {navIntegra.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e("nav-item")}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e("nav-item-icon")} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            {navBridgingIntegra.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e("nav-item")}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e("nav-item-icon")} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}

            {navIndoApotik.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e("nav-item")}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e("nav-item-icon")} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            {navBridgingGreen.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e("nav-item")}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e("nav-item-icon")} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            {navBridgingRainbowPlus.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e("nav-item")}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e("nav-item-icon")} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
            {navBridgingCenturyRainbowPlus.map(
              ({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item")}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              )
            )}
            {navBridgingPelapakinternal.map(
              ({ to, name, exact, Icon }, index) => (
                <NavItem key={index} className={bem.e("nav-item")}>
                  <BSNavLink
                    id={`navItem-${name}-${index}`}
                    className="text-uppercase"
                    tag={NavLink}
                    to={to}
                    activeClassName="active"
                    exact={exact}
                  >
                    <Icon className={bem.e("nav-item-icon")} />
                    <span className="">{name}</span>
                  </BSNavLink>
                </NavItem>
              )
            )}
            {/* {navOrderAO.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e("nav-item")}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e("nav-item-icon")} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))} */}
            {navOrderAO.map(
              ({ to, id, name, exact, Icon }, index) =>
                Object.keys(accessList).includes(id) && (
                  <NavItem key={index} className={bem.e("nav-item")}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e("nav-item-icon")} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                )
            )}

            {/* Sale Rainbow Plus */}
            {navRainbowPlus.map(
              ({ to, id, name, exact, Icon }, index) =>
                Object.keys(accessList).includes(id) && (
                  <NavItem key={index} className={bem.e("nav-item")}>
                    <BSNavLink
                      id={`navItem-${name}-${index}`}
                      className="text-uppercase"
                      tag={NavLink}
                      to={to}
                      activeClassName="active"
                      exact={exact}
                    >
                      <Icon className={bem.e("nav-item-icon")} />
                      <span className="">{name}</span>
                    </BSNavLink>
                  </NavItem>
                )
            )}

            {/* Product Plafon */}
            {navProductPlafon.map(({ to, id, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e("nav-item")}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className="text-uppercase"
                  tag={NavLink}
                  to={to}
                  activeClassName="active"
                  exact={exact}
                >
                  <Icon className={bem.e("nav-item-icon")} />
                  <span className="">{name}</span>
                </BSNavLink>
              </NavItem>
            ))}

            {/* DONOR APOTEK ONLINE */}
            <Nav vertical>
              <NavItem
                className={bem.e("nav-item")}
                onClick={this.handleClick("Donor")}
              >
                <BSNavLink className={bem.e("nav-item-collapse")}>
                  <div className="d-flex">
                    <MdFormatListBulleted className={bem.e("nav-item-icon")} />
                    <span className="">Donor Apotek Online</span>
                  </div>
                  <MdKeyboardArrowDown
                    className={bem.e("nav-item-icon")}
                    style={{
                      padding: 0,
                      transform: this.state.isOpenPelapak
                        ? "rotate(0deg)"
                        : "rotate(-90deg)",
                      transitionDuration: "0.3s",
                      transitionProperty: "transform",
                    }}
                  />
                </BSNavLink>
              </NavItem>
              <Collapse isOpen={this.state.isOpenDonor}>
                {navDonorApotekOnline.map(
                  ({ to, name, exact, Icon }, index) => (
                    <NavItem key={index} className={bem.e("nav-item")}>
                      <BSNavLink
                        id={`navItem-${name}-${index}`}
                        className="text-uppercase"
                        tag={NavLink}
                        to={to}
                        activeClassName="active"
                        exact={exact}
                      >
                        <Icon className={bem.e("nav-item-icon")} />
                        <span className="">{name}</span>
                      </BSNavLink>
                    </NavItem>
                  )
                )}
              </Collapse>
            </Nav>
            {/* DONOR APOTEK ONLINE */}
          </Nav>
        </div>
      </aside>
    );
  }
}

export default Sidebar;
