import React from "react";
import { Redirect, Route } from "react-router-dom";
import WarningPage from "pages/template/warningPage";

function getPermission(page, Component, rest) {
  // console.log("Component  ", Component);
  // console.log(page.location.pathname);
//   console.log("rest", rest);

  var isAccess = false;
  var result = false;
  var accessList = { 25: [52, 53] };
  if (window.localStorage.getItem("accessList")) {
    accessList = JSON.parse(window.localStorage.getItem("accessList"));
    // console.log("asd", accessList);
    result = true;
  } else {
    result = false;
  }

  //isAccess =true   Allow access page

  if (
    page.location.pathname === "/resetpassword" &&
    page.location.state !== undefined
  ) {
    //block Access to URL by URL type-in browser
    isAccess = false;
  }

  if (result === true) {
    if (
      page.location.pathname === "/login" ||
      page.location.pathname === "/lupapassword" ||
      page.location.pathname === "/resetpassword"
    ) {
      return (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      );
    } else {
      //check acces in accessList
      isAccess = Object.keys(accessList).includes(rest.menuID);
    //   console.log("isaccess", Object.keys(accessList).includes(rest.menuID));
    }
  } else {
    if (
      page.location.pathname !== "/login" &&
      page.location.pathname !== "/lupapassword" &&
      page.location.pathname !== "/resetpassword"
    ) {
      alert("SILAKAN LOGIN TERLEBIH DAHULU");
      return (
        <Redirect
          to={{
            pathname: "/login",
            // state: { from: page }
          }}
        />
      );
    }
  }

  if (isAccess === true) {
    return <Component {...page} {...rest} />;
  } else {
    // console.log({ ...page });
    return <WarningPage {...page} />;
  }
}

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(page) => getPermission(page, Component, { ...rest })}
  />
);

export default PrivateRoute;
