const dotenv = require("dotenv");
dotenv.config();

if (window.location.hostname.includes("staging-integra")) {
  //base url
  var base_url_all =
    "https://staging-api.cfu.pharmalink.id/integra-master-produk/";
  var url_masterProduk =
    "https://staging-api.cfu.pharmalink.id/master-produk/produk";
  var url_masterProdukV2 =
    "https://staging-api.cfu.pharmalink.id/integra/v2/products";
  var url_bridging =
    "https://staging-api.cfu.pharmalink.id/bridging-integra/data?";
  var url_indoApotik =
    "https://staging-api.cfu.pharmalink.id/bridging-integra/data?";
  var url_rainbowPlus =
    "https://staging-api.cfu.pharmalink.id/bridging-integra/data?";
  var url_centuryRainbowPlus =
    "https://staging-api.cfu.pharmalink.id/bridging-integra/data?";
  var url_pelapakinternal =
    "https://staging-api.cfu.pharmalink.id/bridging-integra/data?";
  var url_integra_stock =
    "https://staging-api.cfu.pharmalink.id/integra-stock/";
  var url_product_plafon =
    "https://staging-api.cfu.pharmalink.id/integra/v2/productplafon";

  var activeBrandIDIndo = "14634";
  var activeBrandID = "14660";
  var activeBrandIDGreen = "15341";
  var activeBrandIDRainbowPlus = "RainbowPlus";
  var activeBrandIDCenturyRainbowPlus = "CenturyRainbowPlus";
  var activeBrandIDPelapakinternal = "PelapakInternal";
  /* JIKA SUDAH KONEK DENGAN SERVER STAGING BUKAN LOKAL */

  //URL LOGIN
  var url_login = "https://staging-api.cfu.pharmalink.id/auth/login";
  var url_changeForgottenPassword =
    "https://staging-api.cfu.pharmalink.id/auth/forgotpassword";
  var url_verifyOTP = "https://staging-api.cfu.pharmalink.id/auth/verifyotp";
  var url_changePassword =
    "https://staging-api.cfu.pharmalink.id/auth/changepassword";
  var url_loginChangePassword =
    "https://staging-api.cfu.pharmalink.id/auth/changepassword";
  var url_checkRights =
    "https://staging-api.cfu.pharmalink.id/auth/checkrights";
  var url_orderAO =
    "https://staging-api.cfu.pharmalink.id/integra-recv-order/recv";
  var url_dataoutlet = `https://staging-api.cfu.pharmalink.id/bridging-integra/`;

  var url_getSalesRainbowPlus = `https://staging-api.cfu.pharmalink.id/integra/v2/bridging?`;

  var url_getLinkAuthShopee = `https://staging-api.cfu.pharmalink.id/shopee-api-client/apiv2/geturlauthshopeebyoutlet?`;
  var url_getShopeeInfo = `https://staging-api.cfu.pharmalink.id/shopee-api-client/apiv2/getshopinfo?`;
  var url_expiredShopeeApi = `https://staging-api.cfu.pharmalink.id/shopee-api-client/api/getexpiredapi?`;
} else if (window.location.hostname.includes("integra")) {
  // //base url
  var base_url_all = "https://api.cfu.pharmalink.id/integra-master-produk/";
  var url_masterProduk = "https://api.cfu.pharmalink.id/master-produk/produk";
  var url_masterProdukV2 = "https://api.cfu.pharmalink.id/integra/v2/products";
  var url_bridging = "https://api.cfu.pharmalink.id/bridging-integra/data?";
  var url_indoApotik = "https://api.cfu.pharmalink.id/bridging-integra/data?";
  var url_centuryRainbowPlus =
    "https://api.cfu.pharmalink.id/bridging-integra/data?";
  var url_rainbowPlus = "https://api.cfu.pharmalink.id/bridging-integra/data?";
  var url_pelapakinternal =
    "https://api.cfu.pharmalink.id/bridging-integra/data?";
  var url_integra_stock = "https://api.cfu.pharmalink.id/integra-stock/";
  var url_product_plafon =
    "https://api.cfu.pharmalink.id/integra/v2/productplafon";
  var activeBrandIDIndo = "14368";
  var activeBrandID = "14367";
  var activeBrandIDGreen = "15377";
  var activeBrandIDRainbowPlus = "RainbowPlus";
  var activeBrandIDCenturyRainbowPlus = "CenturyRainbowPlus";
  var activeBrandIDPelapakinternal = "PelapakInternal";
  // /* JIKA SUDAH KONEK DENGAN SERVER PRODUCTION BUKAN LOKAL */

  //URL LOGIN
  var url_login = "https://api.cfu.pharmalink.id/auth/login";
  var url_changeForgottenPassword =
    "https://api.cfu.pharmalink.id/auth/forgotpassword";
  var url_verifyOTP = "https://api.cfu.pharmalink.id/auth/verifyotp";
  var url_changePassword = "https://api.cfu.pharmalink.id/auth/changepassword";
  var url_loginChangePassword =
    "https://api.cfu.pharmalink.id/auth/changepassword";
  var url_checkRights =
    "https://staging-api.cfu.pharmalink.id/auth/checkrights";
  var url_orderAO = "https://api.cfu.pharmalink.id/integra-recv-order/recv";
  var url_dataoutlet = `https://api.cfu.pharmalink.id/bridging-integra/`;
  var url_getSalesRainbowPlus = `https://api.cfu.pharmalink.id/integra/v2/bridging?`;
  var url_getLinkAuthShopee = `https://api.cfu.pharmalink.id/shopee-api-client/apiv2/geturlauthshopeebyoutlet?`;
  var url_getShopeeInfo = `https://api.cfu.pharmalink.id/shopee-api-client/apiv2/getshopinfo?`;
  var url_expiredShopeeApi = `https://api.cfu.pharmalink.id/shopee-api-client/api/getexpiredapi?`;
} else if (window.location.hostname.includes("localhost")) {
  //base url
  var base_url_all =
  "https://staging-api.cfu.pharmalink.id/integra-master-produk/";
  var url_masterProduk =
  "https://staging-api.cfu.pharmalink.id/master-produk/produk";
  var url_masterProdukV2 =
  "https://staging-api.cfu.pharmalink.id/integra/v2/products";
  var url_bridging =
  "https://staging-api.cfu.pharmalink.id/bridging-integra/data?";
  var url_indoApotik =
  "https://staging-api.cfu.pharmalink.id/bridging-integra/data?";
  var url_rainbowPlus =
  "https://staging-api.cfu.pharmalink.id/bridging-integra/data?";
  var url_centuryRainbowPlus =
  "https://staging-api.cfu.pharmalink.id/bridging-integra/data?";
  var url_pelapakinternal =
  "https://staging-api.cfu.pharmalink.id/bridging-integra/data?";
  var url_integra_stock =
  "https://staging-api.cfu.pharmalink.id/integra-stock/";
  var url_product_plafon =
  "https://staging-api.cfu.pharmalink.id/integra/v2/productplafon";

  var activeBrandIDIndo = "14634";
  var activeBrandID = "14660";
  var activeBrandIDGreen = "15341";
  var activeBrandIDRainbowPlus = "RainbowPlus";
  var activeBrandIDCenturyRainbowPlus = "CenturyRainbowPlus";
  var activeBrandIDPelapakinternal = "PelapakInternal";
  /* JIKA SUDAH KONEK DENGAN SERVER STAGING BUKAN LOKAL */

  //URL LOGIN
  var url_login = "https://api.cfu.pharmalink.id/auth/login";
  var url_changeForgottenPassword =
  "https://staging-api.cfu.pharmalink.id/auth/forgotpassword";
  var url_verifyOTP = "https://staging-api.cfu.pharmalink.id/auth/verifyotp";
  var url_changePassword =
  "https://staging-api.cfu.pharmalink.id/auth/changepassword";
  var url_loginChangePassword =
  "https://staging-api.cfu.pharmalink.id/auth/changepassword";
  var url_checkRights =
  "https://staging-api.cfu.pharmalink.id/auth/checkrights";
  var url_orderAO =
  "https://staging-api.cfu.pharmalink.id/integra-recv-order/recv";
  var url_dataoutlet = `https://staging-api.cfu.pharmalink.id/bridging-integra/`;

  var url_getSalesRainbowPlus = `https://staging-api.cfu.pharmalink.id/integra/v2/bridging?`;

  var url_getLinkAuthShopee = `https://staging-api.cfu.pharmalink.id/shopee-api-client/apiv2/geturlauthshopeebyoutlet?`;
  var url_getShopeeInfo = `https://staging-api.cfu.pharmalink.id/shopee-api-client/apiv2/getshopinfo?`;
  var url_expiredShopeeApi = `https://api.cfu.pharmalink.id/shopee-api-client/api/getexpiredapi?`;
}

export {
  url_login,
  url_changeForgottenPassword,
  url_verifyOTP,
  url_changePassword,
  url_loginChangePassword,
  base_url_all,
  url_masterProduk,
  url_masterProdukV2,
  url_checkRights,
  url_bridging,
  url_indoApotik,
  url_rainbowPlus,
  url_centuryRainbowPlus,
  url_pelapakinternal,
  url_integra_stock,
  activeBrandIDIndo,
  activeBrandID,
  activeBrandIDGreen,
  activeBrandIDRainbowPlus,
  activeBrandIDCenturyRainbowPlus,
  activeBrandIDPelapakinternal,
  url_orderAO,
  url_dataoutlet,
  url_getSalesRainbowPlus,
  url_getLinkAuthShopee,
  url_getShopeeInfo,
  url_expiredShopeeApi,
  url_product_plafon,
};
