import { STATE_LOGIN, STATE_FORGETPASS } from "components/AuthForm";
import GAListener from "components/GAListener";
import { EmptyLayout, LayoutRoute, MainLayout } from "components/Layout";
import PrivateRoute from "components/Layout/PrivateRoute";
import PageSpinner from "components/PageSpinner";
import AuthPage from "pages/template/AuthPage";
import ResetPasswordForm from "components/ResetPasswordForm";
import React from "react";
import componentQueries from "react-component-queries";
import { BrowserRouter, Redirect, Switch, Route } from "react-router-dom";
import "./styles/reduction.scss";
import * as firebase from "firebase/app";
import "firebase/performance";
import "firebase/auth";
import orderAO from "pages/order_ao/order_ao";
import RegisteredOutlet from "pages/data_outcomco/registeredOutlet";

const firebaseConfig = {
  apiKey: "AIzaSyBF7ptrdf9eU-2imtyk7nK_004VwgBsZcw",
  authDomain: "neo-genesis-development.firebaseapp.com",
  databaseURL: "https://neo-genesis-development.firebaseio.com",
  projectId: "neo-genesis-development",
  storageBucket: "",
  messagingSenderId: "24627510397",
  appId: "1:24627510397:web:5f1059eb90bbb2c5",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

//Template
// const entry = React.lazy(()=> import('pages/auto-assigner-wfh/entry') );
// const entryTeam = React.lazy(()=>import('pages/auto-assigner-wfh/entryWFHTeam'));
// const view = React.lazy(()=>import('pages/auto-assigner-wfh/view'));
// const viewTeam = React.lazy(()=>import('pages/auto-assigner-wfh/viewTeam'));
// const history = React.lazy(()=>import('pages/auto-assigner-wfh/history'));
// const historyTeam = React.lazy(()=>import('pages/auto-assigner-wfh/historyTeam'));

const headerBridging = React.lazy(() =>
  import("pages/bridging_integra/headerBridging")
);

const registrasiOutlet = React.lazy(() =>
  import("pages/data_outcomco/registrasiOutlet")
);

const registeredOutlet = React.lazy(() =>
  import("pages/data_outcomco/registeredOutlet")
);

const indoApotik = React.lazy(() =>
  import("pages/bridging_integra_indoapotik/integraIndoApotik")
);

const bridgingGreen = React.lazy(() =>
  import("pages/bridging_green/bridgingGreen")
);

const bridgingRainbowPlus = React.lazy(() =>
  import("pages/bridging_rainbow_plus/rainbow_plus")
);

const bridgingCenturyRainbowPlus = React.lazy(() =>
  import("pages/bridging_century_rainbow_plus/century_rainbow_plus")
);

const bridgingPelapakInternal = React.lazy(() =>
  import("pages/bridging_pelapakinternal/pelapakinternal")
);

const orderAo = React.lazy(() => import("pages/order_ao/order_ao"));
const home = React.lazy(() => import("pages/MasterProduk/masterIntegraHome"));
const detail = React.lazy(() =>
  import("pages/MasterProduk/masterIntegraDetail")
);
const excel = React.lazy(() => import("pages/MasterProduk/uploadExcel"));

const salesRainbowPlus = React.lazy(() =>
  import("pages/sales_rainbow_plus/salesRainbowPlus")
);

const productPlafon = React.lazy(() =>
  import("pages/product_plafon/product_plafon")
);

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split("/").pop()}`;
};

class App extends React.Component {
  render() {
    return (
      <BrowserRouter basename={getBasename()}>
        <GAListener>
          <Switch>
            <LayoutRoute
              exact
              path="/login"
              layout={EmptyLayout}
              component={(props) => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />
            <LayoutRoute
              exact
              path="/lupapassword"
              layout={EmptyLayout}
              component={(props) => (
                <AuthPage {...props} authState={STATE_FORGETPASS} />
              )}
            />
            <PrivateRoute
              exact
              path="/resetpassword"
              layout={EmptyLayout}
              component={(props) => (
                //<ResetPWd>
                <ResetPasswordForm {...props} />
              )}
            />
            <Route exact menuID="25" path="/">
              {/* <Redirect to="/wfh-view"/> */}
              <Redirect to="/integra-produk" />
            </Route>

            <MainLayout breakpoint={this.props.breakpoint}>
              <React.Suspense fallback={<PageSpinner />}>
                <PrivateRoute
                  exact
                  menuID="25"
                  path="/integra-produk"
                  component={home}
                />
                <PrivateRoute
                  exact
                  menuID="25"
                  path="/integra-produk-detail"
                  component={detail}
                />
                <PrivateRoute
                  exact
                  menuID="25"
                  path="/integra-upload-excel"
                  component={excel}
                />
                <PrivateRoute
                  exact
                  menuID="29"
                  path="/integra-headerBridging"
                  component={headerBridging}
                />
                <PrivateRoute
                  exact
                  menuID="29"
                  path="/integra-registrasiOutlet"
                  component={registrasiOutlet}
                />
                <PrivateRoute
                  exact
                  menuID="29"
                  path="/integra-registeredOutlet"
                  component={registeredOutlet}
                />
                <PrivateRoute
                  exact
                  menuID="29"
                  path="/integra-indoApotik"
                  component={indoApotik}
                />

                <PrivateRoute
                  exact
                  menuID="29"
                  path="/integra-bridgingGreen"
                  component={bridgingGreen}
                />

                <PrivateRoute
                  exact
                  menuID="29"
                  path="/integra-bridgingRainbowPlus"
                  component={bridgingRainbowPlus}
                />

                <PrivateRoute
                  exact
                  menuID="29"
                  path="/integra-bridgingCenturyRainbowPlus"
                  component={bridgingCenturyRainbowPlus}
                />

                <PrivateRoute
                  exact
                  menuID="29"
                  path="/integra-bridgingPelapakinternal"
                  component={bridgingPelapakInternal}
                />

                <PrivateRoute
                  exact
                  menuID="26"
                  path="/order-ao"
                  component={orderAo}
                />

                <PrivateRoute
                  exact
                  menuID="26"
                  path="/sales-rainbowPlus"
                  component={salesRainbowPlus}
                />

                <PrivateRoute
                  exact
                  menuID="25"
                  path="/product-plafon"
                  component={productPlafon}
                />
              </React.Suspense>
            </MainLayout>
            <Redirect to="/integra-produk" />
          </Switch>
        </GAListener>
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: "xs" };
  }

  if (576 < width && width < 767) {
    return { breakpoint: "sm" };
  }

  if (768 < width && width < 991) {
    return { breakpoint: "md" };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: "lg" };
  }

  if (width > 1200) {
    return { breakpoint: "xl" };
  }

  return { breakpoint: "xs" };
};

export default componentQueries(query)(App);
