import React from "react";
import Page from "components/Page";

import {
  Table,
  Input,
  Form,
  FormFeedback,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ButtonGroup,
  Label,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledButtonDropdown,
  InputGroup,
  InputGroupAddon,
  Container,
} from "reactstrap";
import {
  MdSearch,
  MdDetails,
  MdVisibility,
  MdEdit,
  MdLoyalty,
} from "react-icons/md";
import Axios from "axios";
import * as Moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import NotificationSystem from "react-notification-system";
import { NOTIFICATION_SYSTEM_STYLE } from "utils/constants";
import { MdSave, MdClose } from "react-icons/md";
import Select from "react-select";
import { url_dataoutlet, } from "../urlLink";

class RegisteredOutlet extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      resultDataOutlet: [],
      noDataMessage: "none",
      accessList: JSON.parse(window.localStorage.getItem("accessList")),
    };
  }

  componentDidMount() {
    if (this.state.accessList[26].includes(215)) {
      this.getDataOutlet();
      this.interval = setInterval(() => window.location.reload(), 900000);
    } else {
      this.props.history.push("/");
      alert("TIDAK ADA AKSES KE LAYAR ORDER AO");
    }
  }
  getDataOutlet() {
    this.setState({ isLoading: true });
    var urlGetDataOutlet = url_dataoutlet+"data?type=getallinsertedoutlet";
    Axios.get(urlGetDataOutlet)
      .then(response => {
        if (response.data.data === null) {
          this.setState({
            valid: true,
            isLoading: false,
        }, () => this.setState({
            resultDataOutlet: [],
            valid: false,
            isLoading: false,
            noDataMessage: "block"
        }), () => console.log("",))
        }
        else if (response.data.data !== null) {
          this.setState({
              noDataMessage: "none",
              resultDataOutlet: response.data.data,
              valid: false,
              isLoading: false,
          })
      }
      // this.setState({
      //     maxPage: response.data.metadata.max_page,
      //     isLoading: false,
      // })
      })
      .catch(error => {
        alert(error.message)
        this.setState({ isLoading: false })
        return;
    })
  }

  UpdateInputTable(evt) {
    this.setState(
      {
        ids: evt.target.value.substr(0, 1),
        names: evt.target.value.substr(2, evt.target.value.length),
      },
      () => this.getDataOutlet()
      //   () => console.log("point", this.state.ids, this.state.names)
    );
  }

  render() {
    const { resultDataOutlet } = this.state;
    return (
      <Page title="Outlet yang Sudah Diregistrasi">
        <Card className="mb-3">
          <CardBody>
            <Table responsive>
              {/* <NotificationSystem
                dismissible={false}
                ref={(notificationSystem) =>
                  (this.notificationSystem = notificationSystem)
                }
                style={NOTIFICATION_SYSTEM_STYLE}
              /> */}

              <thead>
                <tr align="center">
                  <th align="center">Out Code</th>
                  <th align="center">Comco</th>
                  <th align="center">Nama</th>
                  <th align="center">Tanggal Input</th>
                </tr>
              </thead>
              <tbody>
                {resultDataOutlet && resultDataOutlet.map((item, index) => (
                  <tr align="center">
                  <td>{item.OutCode}</td>
                  <td>{item.OutComco}</td>
                    <td>{item.OutName}</td>
                    {/* <td>{item.IntegraLastUpdate}</td> */}
                    <td>
                            {item.IntegraLastUpdate === ""
                              ? ""
                              : Moment(item.IntegraLastUpdate).format(
                                  "DD MMMM YYYY"
                                )}
                          </td>
                  </tr>
                ))}
              </tbody>
            </Table>
           
  
            <Modal
              isOpen={this.state.isLoading}
              style={{
                position: "relative",
                marginTop: "20%",
                width: "15%",
                opacity: "100%",
              }}
            >
              <ModalBody>
                <Form
                  style={{
                    textAlign: "center",
                    display: "block",
                  }}
                >
                  <ClipLoader
                    style={{ align: "center" }}
                    size={90}
                    color={"#123abc"}
                  ></ClipLoader>
                </Form>
              </ModalBody>
            </Modal>

            <p
              className={"text-center"}
              style={{
                display: this.state.noDataMessage,
              }}
            >
              Tidak ada hasil
            </p>
          </CardBody>
        </Card>
      </Page>
    );
  }
}
export default RegisteredOutlet;
