import React from "react";
import Page from "components/Page";
import * as Moment from "moment";
import ClipLoader from "react-spinners/ClipLoader";
import { url_orderAO } from "../urlLink";

const {
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  CardFooter,
  Modal,
  ModalBody,
  Form,
} = require("reactstrap");

class orderAO extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultDataOrderAO: [],
      isLoading: false,
      ids: "N",
      noDataMessage: "none",
      accessList: JSON.parse(window.localStorage.getItem("accessList")),
    };
  }

  componentDidMount() {
    if (this.state.accessList[26].includes(215)) {
      this.getDataOrderAO();
      this.interval = setInterval(() => window.location.reload(), 900000);
    } else {
      this.props.history.push("/");
      alert("TIDAK ADA AKSES KE LAYAR ORDER AO");
    }
  }
  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }

  getDataOrderAO() {
    this.setState({ isLoading: true });
    var urlGetOrderAO = url_orderAO + "?get=orderStat";
    var payload = {
      confirm_yn: this.state.ids,
      req_pickup_yn: "N",
      end_date: "2021-12-01 03:30:00",
    };
    const option = {
      method: "POST",
      json: true,
      //   headers: {
      //     "Content-Type": "application/json;charset=UTF-8",
      //     Authorization: window.localStorage.getItem("tokenLogin"),
      //   },
      body: JSON.stringify(payload),
    };
    // console.log("payload", payload);
    fetch(urlGetOrderAO, option)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
        }
      })
      .then((data) => {
        // console.log("res", data.data !== null);
        if (data.data !== null) {
          this.setState({
            isLoading: false,
            resultDataOrderAO: data.data,
            noDataMessage: "none",
          });
        } else {
          this.setState({
            isLoading: false,
            resultDataOrderAO: [],
            noDataMessage: "block",
          });
        }
      })
      .catch((error) => {
        alert(error.message);
        this.setState({
          isLoading: false,
          resultDataOrderAO: [],
          noDataMessage: "block",
        });
      });
  }

  UpdateInputTable(evt) {
    this.setState(
      {
        ids: evt.target.value.substr(0, 1),
        names: evt.target.value.substr(2, evt.target.value.length),
      },
      () => this.getDataOrderAO()
      //   () => console.log("point", this.state.ids, this.state.names)
    );
  }

  render() {
    const { resultDataOrderAO } = this.state;

    return (
      <Page
      // title="ORDERAN APOTEK ONLINE LEBIH DARI 2 JAM BELUM DI ACCEPT"
      >
        <Col>
          <h1 style={{ fontWeight: "bold", textAlign: "center", color: "red" }}>
            ORDERAN APOTEK ONLINE LEBIH DARI 2 JAM BELUM DI ACCEPT
          </h1>
        </Col>
        <Row>
          <Col>
            <Card className="mb-3">
              <CardHeader className="d-flex justify-content-between">
                <Col
                  style={{
                    marginBottom: "0%",
                  }}
                >
                  <select
                    className="custom-select"
                    color="green"
                    onChange={(evt) => this.UpdateInputTable(evt)}
                    style={{
                      width: "20%",
                      marginRight: "5%",
                      marginBottom: "0%",
                    }}
                  >
                    <option value={["N", "BelumDilayani"]}>
                      Belum Dilayani
                    </option>
                    <option value={["Y", "SudahDilayani"]}>
                      Sudah Dilayani
                    </option>
                  </select>
                </Col>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead>
                    <tr align="center">
                      <th align="center">Outcode</th>
                      <th align="center">Nama Apotek</th>
                      <th align="center">Nomor Pesanan</th>
                      <th align="center">Waktu Bayar</th>
                      <th align="center">Lama Waktu</th>
                      {/* <th align="center">Status</th>
                    <th align="center">Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {resultDataOrderAO &&
                      resultDataOrderAO.map((items, index) => (
                        <tr align="center">
                          <td>{items.outcode_owner}</td>
                          <td>{items.outname_owner}</td>
                          <td>{items.order_id_ecommerce}</td>
                          <td>
                            {items.order_date === ""
                              ? ""
                              : Moment(items.order_date).format(
                                  "Do MMM YYYY, h:mm:ss "
                                )}
                          </td>
                          <td>{Moment(items.order_date).fromNow()}</td>
                          {/* <td>{items.Integra_OutNameOwner}</td>
                        <td>{items.Integra_ComcoOwner}</td> */}
                        </tr>
                      ))}
                  </tbody>
                </Table>

                <Modal
                  isOpen={this.state.isLoading}
                  style={{
                    position: "relative",
                    marginTop: "20%",
                    width: "15%",
                    opacity: "100%",
                  }}
                >
                  <ModalBody>
                    <Form
                      style={{
                        textAlign: "center",
                        display: "block",
                      }}
                    >
                      {" "}
                      <ClipLoader
                        style={{ align: "center" }}
                        size={90}
                        color={"#123abc"}
                      ></ClipLoader>
                    </Form>
                  </ModalBody>
                </Modal>

                <p
                  className={"text-center"}
                  style={{
                    display: this.state.noDataMessage,
                  }}
                >
                  Tidak ada hasil
                </p>
              </CardBody>
              <CardFooter></CardFooter>
            </Card>
          </Col>
        </Row>
      </Page>
    );
  }
}
export default orderAO;
